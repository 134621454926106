

<template>
    <Layout>
        <PageHeader :title="title" :items="[]" />
    <loading-vue v-if="load"></loading-vue>
    
        <div class="row g-2" v-else>
            <div class="col-md-5 mx-auto">

    
              

                

                                       <div class="card">
                                    <div class="card-header align-items-center d-flex">
                                        <h4 class="card-title mb-0 flex-grow-1">Airtime Pricing</h4>
                                        <div class="flex-shrink-0">
                                            
                                        </div>
                                    </div><!-- end card header -->

                                    <div class="card-body">
                                       
                                        <div class="live-preview">
                                            <div class="table-responsive">
                                                <table class="table align-middle table-nowrap mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Process</th>
                                                            <th scope="col">User</th>
                                                            <th scope="col">Reseller</th>
                                                         
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="airtime in airtimes" :key="airtime.id">
                                                            <th scope="row">   <b-img :src="link + airtime.image" alt="" class="rounded-circle avatar-xs"></b-img>
                   <span class="ms-2"><strong> {{ airtime.name }}</strong></span></th>
                                                            <td> Airtime</td>
                                                            <td><span class="text-danger">{{airtime.resellar}}%</span></td>
                                                            <td><span class="text-success">{{airtime.ref}}%</span></td>
                                                           
                                                        </tr>
                                                        
                                                    </tbody>
                                                </table>
                                           
                                    </div>
                                </div>
      </div>
   
    </div>


    <div class="card">
                                    <div class="card-header align-items-center d-flex">
                                        <h4 class="card-title mb-0 flex-grow-1">Data Plans</h4>
                                        <div class="flex-shrink-0">
                                            
                                        </div>
                                    </div><!-- end card header -->

                                    <div class="card-body">
                                       
                                        <div class="live-preview">
                                            <div class="table-responsive">
                                                <table class="table align-middle table-nowrap mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Process</th>
                                                            <th scope="col">User</th>
                                                            <th scope="col">Reseller</th>
                                                         
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="data in datas" :key="data.id">
                                                            <th scope="row">   <b-img :src="link + data.data.image" alt="" class="rounded-circle avatar-xs"></b-img>
                   <span class="ms-2"><strong> {{ data.data.name}}</strong></span></th>
                                                            <td>{{data.name}}</td>
                                                            <td><span class="text-danger">₦{{data.price}}</span></td>
                                                            <td><span class="text-success">₦{{data.data.resellar > 0 ? dataprice(data)  :data.price }}</span></td>
                                                           
                                                        </tr>
                                                        
                                                    </tbody>
                                                </table>
                                           
                                    </div>
                                </div>
      </div>
   
    </div>
     

    <div class="card">
                                    <div class="card-header align-items-center d-flex">
                                        <h4 class="card-title mb-0 flex-grow-1">CableTv Pricing</h4>
                                        <div class="flex-shrink-0">
                                            
                                        </div>
                                    </div><!-- end card header -->

                                    <div class="card-body">
                                       
                                        <div class="live-preview">
                                            <div class="table-responsive">
                                                <table class="table align-middle table-nowrap mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Process</th>
                                                            <th scope="col">User</th>
                                                            <th scope="col">Reseller</th>
                                                         
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="cabletv in cabletvs" :key="cabletv.id">
                                                            <th scope="row">   <b-img :src="link + cabletv.image" alt="" class="rounded-circle avatar-xs"></b-img>
                   <span class="ms-2"><strong> {{ cabletv.name }}</strong></span></th>
                                                            <td> CableTv pricing</td>
                                                            <td><span class="text-danger">No discount</span></td>
                                                            <td><span class="text-success">{{cabletv.resellar == null ? 0.00: cabletv.resellar}}%</span></td>
                                                           
                                                        </tr>
                                                        
                                                    </tbody>
                                                </table>
                                           
                                    </div>
                                </div>
      </div>
   
    </div>



    <div class="card">
                                    <div class="card-header align-items-center d-flex">
                                        <h4 class="card-title mb-0 flex-grow-1">Electricity Pricing</h4>
                                        <div class="flex-shrink-0">
                                            
                                        </div>
                                    </div><!-- end card header -->

                                    <div class="card-body">
                                       
                                        <div class="live-preview">
                                            <div class="table-responsive">
                                                <table class="table align-middle table-nowrap mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Process</th>
                                                            <th scope="col">User</th>
                                                            <th scope="col">Reseller</th>
                                                         
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="electricity in electricities" :key="electricity.id">
                                                            <th scope="row">   <b-img :src="link + electricity.image" alt="" class="rounded-circle avatar-xs"></b-img>
                   <span class="ms-2"><strong> {{ electricity.name }}</strong></span></th>
                                                            <td>Electricity</td>
                                                            <td><span class="text-danger">No Discount</span></td>
                                                            <td><span class="text-success">{{electricity.resellar}}%</span></td>
                                                           
                                                        </tr>
                                                        
                                                    </tbody>
                                                </table>
                                           
                                    </div>
                                </div>
      </div>
   
    </div>

     
                
            </div>
            <!-- end col -->
        </div>
        <!--end row-->
    
    
    </Layout>
    
</template>






<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import axios from 'axios';
import Swal from "sweetalert2";

import LoadingVue from "./components/Loading.vue";


export default {
    page: {
        title: "Quick Deposit",
        meta: [{ name: "description", content: appConfig.description }],
    },
    data() {
        return {
            link: process.env.VUE_APP_URL,
            title: "Pricing",
             amount: null,
            load: false,
            airtimes: [],
            cabletvs: [],
            electricities: [],
            datas: [],
            items: [],
        };
    },
    name: "Widgets",
    components: {
        Layout,

        PageHeader,
        LoadingVue,
  
    },
    
    created() {
   this.getairtime();
   this.getdata();
   this.getelectricity();
   this.getcabletv();
    },
    computed: {

       

  



      user(){
         return this.$store.getters['getuser'];
      },
      
    },
    methods: {
        dataprice(data) {

            if (data.discount > 0) {
                return data.price - ((data.discount/100) * data.price)
                
            }else if(data.data.resellar > 0){

                return data.price - ((data.data.resellar/100) * data.price)


            }else {


                return data.price;
            }





},
    async getairtime () {

        this.load = true;
            await axios.get(process.env.VUE_APP_MAIN_URL + "/get/pricing/airtime").catch((error) => {

this.load = false;
                Swal.fire("Error!", "Please Check Your Internet Connection", "error");
                throw error;

            }).then((response) => {
this.load = false;
                if (response.data.status == true) {

                    this.airtimes = response.data.data.data;
              


                }

            })
     },


     async getdata () {

this.load = true;
    await axios.get(process.env.VUE_APP_MAIN_URL + "/get/pricing/data").catch((error) => {

this.load = false;
        Swal.fire("Error!", "Please Check Your Internet Connection", "error");
        throw error;

    }).then((response) => {
this.load = false;
        if (response.data.status == true) {

            this.datas = response.data.data.data;
      


        }

    })
},

async getcabletv () {

this.load = true;
    await axios.get(process.env.VUE_APP_MAIN_URL + "/get/pricing/cabletv").catch((error) => {

this.load = false;
        Swal.fire("Error!", "Please Check Your Internet Connection", "error");
        throw error;

    }).then((response) => {
this.load = false;
        if (response.data.status == true) {

            this.cabletvs = response.data.data.data;
      


        }

    })
}
,

async getelectricity () {

this.load = true;
    await axios.get(process.env.VUE_APP_MAIN_URL + "/get/pricing/electricity").catch((error) => {

this.load = false;
        Swal.fire("Error!", "Please Check Your Internet Connection", "error");
        throw error;

    }).then((response) => {
this.load = false;
        if (response.data.status == true) {

            this.electricities = response.data.data.data;
      


        }

    })
}
  }

};
</script>
<style scoped>
.active {
    border: 2px solid green;
}
</style>